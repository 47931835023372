.Card {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 4px rgba(155, 155, 155, 0.527);
  position: relative;
  height: 400px;
  width: 300px;
  cursor: default;
  transform: scale(1);
  transition: all 300ms ease;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.Animated {
  transform: scale(1);
}

.Animated:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 20px rgba(155, 155, 155, 0.527);
}

.Image {
  height: 185px;
  width: 100%;
  filter: blur(0) brightness(70%);
  transition: filter 300ms ease;
  border-radius: 4px 4px 0px 0px;
}

.Title {
  font-size: 1.6rem;
  padding: 0 12px;
  position: absolute;
  line-height: 1.2rem;
  top: 42%;
  transform: translateY(-50%);
  color: white;
}

.Content {
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: rgb(99, 99, 99);
  margin: 2px 15px 15px 15px;
  padding-top: 8px;
  border-top: 1px solid rgb(209, 209, 209);
}

.Link {
  text-decoration: none;
  padding: 3px 10px;
  color: #790000;
  border-left: 6px solid #790000;
  font-weight: bold;
  font-size: 1rem;
  background-color: rgb(231, 231, 231);
  border-radius: 0 0 2px 2px;
  text-shadow: none;
  transition: all 250ms ease;
}

.Link:hover {
  color: #af2a2a;
  border-left: 20px solid #af2a2a;
  text-shadow: 0 0 4px #ffa1a1;
  background-color: rgb(248, 248, 248);
}

@media (max-width: 1650px) {
  .Card {
    margin: 10px;
  }
}

@media (max-width: 750px) {
  .Card:hover {
    transform: scale(1);
    box-shadow: 0 3px 4px rgba(155, 155, 155, 0.527);
  }
}