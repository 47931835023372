.FootMenu {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  color: #790000a8;
  box-shadow: 0px 0px 4px #5e5e5e;
}

.FootMenu a {
  text-decoration: none;
  color: inherit;
}

.Btn:not(:last-child) {
  border-right: 1px solid rgb(219, 219, 219);
}

.Btn {
  flex: 1;
  font-size: 0.7rem;
  text-align: center;
  padding-top: 10px;
}

.Icon {
  font-size: 1.6rem;
}

@media (max-width: 450px) {
  .FootMenu {
    display: flex;
  }
}
