.Social {
    grid-area: social;
    font-size: 2rem;
}

.Icon {
    padding: 20px;
    text-decoration: none;
    cursor: pointer;
    transition: all 250ms ease;
}

.IconRed {
    color: #790000;
}

.IconGold {
    padding: 0px 10px;
    color: inherit;
    font-size: 1.8rem;
    color: #ad9e37;
}

.Icon:hover {
    color: #b6b6b6;
}