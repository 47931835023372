.Wrapper {
  position: fixed;
  width: 100vw;
  z-index: 100;
  background-color: #ffffff;
  top: 0;
  left: 0;
}

.Navbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: ". menu logo . .";
  box-shadow: 0 2px 4px #c9c9c9;
}

.Menu {
  grid-area: menu;
  padding: 10px;
  position: relative;
  width: 100px;
}

.Logo {
  grid-area: logo;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url("../../assets/img/logo.png");
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Logo {
  text-decoration: none;
}
.Logo h1 {
  font-size: 2rem;
  padding: 5px 0;
  cursor: pointer;
  color: #790000;
  font-family: "Great Vibes", cursive;
}

.Btn {
  border-bottom: 3px solid #202020;
  height: 4px;
  width: 25px;
  cursor: pointer;
}

@media (max-width: 1251px) {
  .Navbar {
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
    grid-template-areas: ". menu logo . .";
  }
}

@media (max-width: 751px) {
  .Navbar {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: "menu logo .";
  }
}

@media (max-width: 500px) {
  .Logo h1 {
    font-size: 1rem;
    padding-top: 10px;
  }
}
