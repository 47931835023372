.NotFound {
    height: 50vh;
    background-color: #aaaaaa;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 2rem;
    text-align: center;
}

.Url {
    color: #383838;
}

.NotFound span {
    color: #790000;
}

@media(max-width: 500px) {
    .NotFound {
        font-size: 1.4rem;
    }
}