.Wrapper {
    height: 700px;
    width: 100%;
    position: relative;
}

.Contact {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 900px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.Contact h4 {
    font-size: 2.3rem;
    color: rgb(58, 58, 58);
}

.Form {
    display: flex;
    flex-flow: column;
    padding: 20px;
    width: 400px;
}

.Form input, .Form textarea {
    padding: 5px;
    font-family: inherit;
    margin-bottom: 10px;
    border: none;
    outline: none;
    border-bottom: 3px solid #790000;
    background-color: rgb(245, 245, 245);
}

.Button {
    margin-top: 15px;
    padding: 10px 20px;
    width: 65%;
    border: none;
    outline: none;
    background-color: #790000;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 250ms ease;
}

.Button:hover {
    background-color: #c56161;
}

.Background {
    height: 500px;
    width: 900px;
    background-image: linear-gradient(rgba(248, 155, 155, 0.623), white), url("../../assets/img/private_music_lessions.jpeg");
    background-size: cover;
    clip-path: polygon(0 0, 55% 0, 89% 100%, 0% 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media(max-width: 900px) {
    .Contact {
        height: 500px;
        width: 600px;
    }
    .Contact h4 {
        font-size: 1.5rem;
    }
    .Background {
        height: 500px;
        width: 600px;
    }
}

@media(max-width: 500px) {
    .Wrapper {
        height: 700px;
        width: 100vw;
    }
    .Contact {
        height: 400px;
        width: 350px;
    }
    .Contact h4 {
        font-size: 1rem;
        padding: 0 10px;
    }
    .Form {
        padding: 10px;
        width: 300px;
    }
    .Background {
        height: 400px;
        width: 350px;
    }
}