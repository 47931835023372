.SolidFoundation h1 {
    font-size: 1.8rem;
    color: #646464;
    padding: 120px 40px;
    margin-top: 50px;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(rgba(255, 249, 212, 0.71), rgba(255, 249, 212, 0.71)), url("../../assets/img/solid_foundation_backing.jpg");
}

.SolidFoundation h1 span {
    color: #790000;
    border-left: 4px solid rgb(61, 61, 61);
    padding-left: 7px;
}

.Container {
    padding: 40px 150px;
    font-size: .9rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "body body" "img list" "conc conc";
}

.SolidTxt {
    color: #790000;
    font-weight: bold;
}

.Body {
    grid-area: body;
    margin-bottom: 25px;
}

.List {
    grid-area: list;
    font-size: .85rem;
    margin-bottom: 25px;
    padding: 0 20px;
}

.List p {
    font-weight: bold;
    border-bottom: 1px solid rgb(124, 124, 124);
    color: rgb(124, 124, 124);
    margin-bottom: 20px;
}

.List li {
    list-style-type: none;
}

.Icon {
    padding-right: 10px;
    color: rgb(212, 198, 67);
}

.Conc {
    grid-area: conc;
    margin-bottom: 40px;
}

.Img {
    grid-area: img;
    height: 280px;
}

@media(max-width: 1050px) {
    .Img {
        height: 200px;
    }
}

@media(max-width: 900px) {
    .Img {
        margin-left: auto;
        margin-bottom: 30px;
    }
    .Container {
        padding: 40px 100px;
        grid-template-columns: 1fr;
        grid-template-areas: "body" "img" "list" "conc";
    }
}

@media(max-width: 550px) {
    .Img {
        margin: auto;
        margin-bottom: 30px;
    }
    .Container {
        padding: 40px;
    }
    .SolidFoundation h1 {
        font-size: 1.4rem;
        color: #646464;
        padding: 120px 53px;
        margin-top: 50px;
        background-position: center;
        background-size: cover;
        background-image: linear-gradient(rgba(255, 249, 212, 0.71), rgba(255, 249, 212, 0.71)), url("../../assets/img/solid_foundation_backing.jpg");
    }
    .SolidFoundation h1 span {
        color: #790000;
        border-left: 4px solid rgb(61, 61, 61);
        padding-left: 7px;
    }
}