.Footer {
  padding: 70px 0;
  background-color: rgb(226, 226, 226);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Main {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-areas: "contact logo social ";
  align-items: center;
  justify-items: center;
}

.Contact {
  grid-area: contact;
  color: rgb(104, 104, 104);
  font-size: 1rem;
  line-height: 22px;
}

.Contact p {
  margin-bottom: 15px;
}

.Contact h2 {
  color: #790000;
  border-bottom: 1px solid rgb(175, 175, 175);
  margin-bottom: 20px;
}

.Contact h2 span {
  color: rgb(173, 173, 173);
  font-size: 1rem;
}

.ContactIcon {
  color: #ad9e37;
  margin-right: 10px;
  font-size: 1rem;
}

.Address {
  display: flex;
}

.Address p {
  margin-bottom: 0;
}

.Logo {
  grid-area: logo;
  text-align: center;
  font-family: "Great Vibes", cursive;
  color: #790000;
  font-size: 1.1rem;
}

.Image {
  height: 120px;
}

.Bot {
  background-color: rgb(192, 192, 192);
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  padding: 10px 0;
}

.Bot p {
  margin: 0 10px;
}

.Bot p a {
  color: inherit;
  transition: all 250ms ease;
}

.Bot p a:hover {
  color: #fff;
}

@media (max-width: 980px) {
  .Main {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "logo logo" "contact social";
  }
  .Logo {
    margin-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .Main {
    grid-template-columns: 1fr;
    grid-template-areas: "logo" "contact" "social";
  }
}

@media (max-width: 412px) {
  .Bot {
    justify-content: center;
  }
}