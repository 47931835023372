.Testimonials {
  padding: 100px 0;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-flow: column;
}

.Testimonials h3 {
  font-size: 1.4rem;
  color: rgb(175, 175, 175);
  margin-bottom: 20px;
}

.Slider {
  position: relative;
  width: 550px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px #808080;
}

.Slide {
  width: 460px;
}

.Prev {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  color: rgb(175, 175, 175);
}
.Next {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  color: rgb(175, 175, 175);
}

.Text {
  font-size: 0.8rem;
  line-height: 1.5rem;
  padding: 20px 0;
}

.Name {
  color: #790000;
  font-size: 1rem;
  font-weight: bold;
}

@media (max-width: 570px) {
  .Slider {
    position: relative;
    height: 430px;
    width: 350px;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
  }

  .Slide {
    width: 260px;
  }
}
