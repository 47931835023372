.Wrapper {
    padding: 100px;
}

.Spacer {
    width: 100px;
    border-bottom: 6px double #790000;
    margin: auto;
    margin-bottom: 20px;
}

.Wrapper h2 {
    font-size: 1.8rem;
    transform: skew(-25deg);
    color: #790000;
    width: fit-content;
    margin: auto;
}

.GreatResources {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: rgb(121, 0, 0);
    background: radial-gradient(ellipse, rgba(121, 99, 0, 0.4) 0%, rgba(255, 255, 255, 1) 75%);
}

@media(max-width: 450px) {
    .Wrapper {
        padding: 100px 10px;
    }
}