.Landing {
  background: linear-gradient(rgba(59, 59, 59, 0.411), rgba(59, 59, 59, 0.479)),
    url("../../assets/img/saxophone_instruction.jpg");
  background-size: cover;
  height: 95vh;
  margin: 0 25px;
  background-attachment: fixed;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    ". . ."
    ". . ."
    ". headline .";
}

.Headline {
  grid-area: headline;
  color: #ffffff;
  display: flex;
  flex-flow: column;
}

.Headline h1 {
  font-size: 3rem;
}
.Headline h4 {
  font-size: 1.4rem;
  font-weight: 200;
  border-left: 6px solid #d1c366;
  padding-left: 10px;
}

.Btn {
  width: fit-content;
  align-self: center;
  padding-top: 60px;
}

@media (max-width: 1214px) {
  .Headline h1 {
    font-size: 2rem;
  }
  .Headline h4 {
    font-size: 1.2rem;
  }
}

@media (max-width: 900px) {
  .Landing {
    grid-template-columns: 1fr 4fr 1fr;
  }
}

@media (max-width: 650px) {
  .Landing {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 10px;
  }
  .Headline h1 {
    font-size: 1.6rem;
    padding: 0 10px;
  }
  .Headline h4 {
    font-size: 1rem;
    margin: 0 10px;
  }
  .Btn {
    width: fit-content;
    align-self: center;
    padding-top: 60px;
    margin-left: 10px;
  }
}
