.About {
  padding-top: 100px;
  position: relative;
  background: transparent;
}

.Background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #ebc3c3;
  clip-path: polygon(0 0, 33% 0, 67% 100%, 0% 100%);
  z-index: -1;
}

.Wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.Wrapper img {
  height: 500px;
  border-radius: 2px;
  margin-bottom: 40px;
  box-shadow: 0 3px 8px rgba(29, 29, 29, 0.767);
  align-self: flex-start;
}

.Info {
  background: rgb(236, 236, 236);
  padding: 0 30px 10px 30px;
  border-radius: 2px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.Bio {
  padding: 10px 0;
}

.Info h2 {
  color: #790000;
  font-size: 2rem;
  line-height: 0.2rem;
}

.Info h1 {
  color: #b6b6b6;
  font-size: 1.4rem;
  margin-left: 30px;
}

.Info h4 {
  color: #474747;
  font-size: 1rem;
}

.Info p, .Info li {
  color: #272727;
  font-size: .8rem;
}

.Info li {
  margin-left: 20px;
  list-style-type: circle;
}

@media(max-width:700px) {
  .About {
    padding-top: 70px;
    position: relative;
    background: transparent;
  }
  .Wrapper {
    grid-template-columns: 1fr;
  }
  .Wrapper img {
    height: 300px;
  }
  .Info {
    margin-right: 0;
  }
}