.Bio {
  display: flex;
  flex-wrap: wrap;
  background-color: #f3f3f3;
  justify-content: center;
  padding: 100px 0;
}

.About {
  width: 60%;
}

.About h2 {
  font-size: 1.6rem;
  color: #790000;
}

.About h1 {
  margin-left: 50px;
  font-size: 2rem;
  color: #ccc;
  line-height: 0.5rem;
}

.About p {
  width: 90%;
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px solid #ccc;
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.Image {
  height: 350px;
  border-radius: 50%;
  box-shadow: 2px 2px 8px #727272;
  justify-self: flex-start;
}

@media (max-width: 780px) {
  .Bio {
    flex-flow: column;
    align-items: center;
  }
  .About {
    width: 80%;
  }
  .About p {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .Bio {
    padding: 100px 8px;
  }
  .About {
    width: 90%;
  }
  .About h2 {
    font-size: 1.2rem;
  }
  .About h1 {
    margin-left: 30px;
    font-size: 1.4rem;
  }
  .About p {
    margin-top: 20px;
    padding-top: 20px;
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
  .Image {
    margin-top: 20px;
    height: 270px;
  }
}