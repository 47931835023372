.Menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  z-index: 100;
}

.List {
  list-style-type: none;
}

.ListItem {
  text-align: center;
}

.Number {
  font-family: "Great Vibes", cursive;
  font-size: 1.4rem;
  color: #790000;
  text-align: center;
  font-weight: bold;
}

.Item {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #b4b4b4;
  transition: all 250ms ease;
}

.Item:hover {
  color: #202020;
}

.Button {
  margin-top: 60px;
}

.Social {
  margin-top: 20px;
}