.Wrapper {
  background-color: #f3f3f3;
}

.Title {
  text-align: center;
  padding: 100px 0 20px 0;
  font-size: 2rem;
  font-family: "Great Vibes", cursive;
  color: #790000;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

.Spacer {
  background: url("../../assets/img/lines.png");
  background-size: 65px;
  background-repeat: no-repeat;
  background-position: top center;
  padding-bottom: 70px;
  width: 100px;
  margin: auto;
}

.Prep {
  padding: 0 10px 100px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  justify-items: center;
}

@media (max-width: 1315px) {
  .Prep {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 700px;
    margin: auto;
  }
}

@media (max-width: 700px) {
  .Prep {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
    padding: 0 0 100px 0;
  }
}
