.Lessons {
  background-color: #ffff;
  position: relative;
}

.Wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 1fr;
  grid-template-areas: ". . textbox ." ". tagline textbox ." ". . textbox .";
  padding: 100px 0;
}

.TaglineWrapper {
  grid-area: tagline;
  text-align: center;
}

.Tagline {
  font-size: 3rem;
  font-family: "Great Vibes", cursive;
  color: #b9a730;
  margin-bottom: 50px;
}

.TextBox {
  font-size: 1rem;
  line-height: 1.7rem;
  padding-left: 50px;
}

.TextBoxWrapper {
  grid-area: textbox;
}

.Text {
  color: rgb(104, 104, 104);
}

.Text:not(:last-child) {
  padding-bottom: 50px;
}

@media (max-width: 1200px) {
  .Tagline {
    font-size: 2.5rem;
  }

  .TextBox {
    line-height: 1.4rem;
  }
}

@media (max-width: 1024px) {
  .Wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . textbox textbox" "tagline tagline textbox textbox" ". . textbox textbox";
    padding: 100px 40px;
  }
}

@media (max-width: 737px) {
  .Wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". textbox" "tagline textbox" ". textbox";
  }
}

@media (max-width: 589px) {
  .Wrapper {
    grid-template-columns: 1fr;
    grid-template-areas: "tagline" "textbox" "textbox";
    padding: 100px 0;
  }
  .Tagline {
    margin-bottom: 20px;
    text-align: center;
  }
  .TextBox {
    margin-top: 20px;
    padding: 30px;
  }
}
