.Btn {
  padding: 10px 40px;
  border-radius: 2px;
  border: none;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  outline: none;
  letter-spacing: 0.1rem;
}

.Solid {
  background-color: #790000;
  color: #ffffff;
}

.Solid:hover {
  background-color: #a73333;
}

.Outline {
  background-color: transparent;
  color: #790000;
  border: 2px solid #790000;
  transition: all 200ms ease;
}

.Outline:hover {
  background-color: #790000;
  color: #ffffff;
}

.Link {
  padding: 0 20px 0 0;
  background-color: transparent;
  border-bottom: 2px solid #790000;
  transition: color 200ms ease, padding 1s ease;
}

.Link:hover {
  padding: 0 200px 0 0;
  color: rgb(139, 139, 139);
}
